// src/pages/login.js
import React, { useState } from "react"
import { navigate, Link } from "gatsby"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const [failedAttempts, setFailedAttempts] = useState(0)

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const formData = new FormData()
      formData.append("username", email)
      formData.append("password", password)

      const response = await fetch(
        `${process.env.BACKEND_API_URL}/auth/login`,
        {
          method: "POST",
          body: formData,
        }
      )

      if (!response.ok) {
        throw new Error("Login failed")
      }

      const data = await response.json()

      localStorage.setItem("token", data.access_token)
      setMessage("Logged in successfully!")
      navigate("/")
    } catch (error) {
      setMessage("Login failed.")
      setFailedAttempts(failedAttempts + 1)
    }
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-4xl text-center my-8">Login</h1>
      <form onSubmit={handleSubmit} className="mx-auto max-w-md">
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Email Address
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-6">
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login
          </button>
        </div>
        {message && <p className="text-center text-red-500">{message}</p>}
        {failedAttempts >= 3 && (
          <div className="mb-6 text-center">
            <button
              onClick={() => navigate("/reset-password")}
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Reset Password
            </button>
          </div>
        )}
        <div className="text-center">
          <Link to="/register" className="text-blue-500 hover:text-blue-700">
            Don't have an account? Register
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Login
